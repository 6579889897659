@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.content{
  margin-top: 85px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.customizeButton{
  border-style: solid;
  border-width: 1px 1px 0px 1px;
  border-color: #FF4D0073;
  background-color: #fff;
  font-size: 22px;
  font-weight: 500;
  line-height: 21.6px;
  color: #000000;
  cursor: pointer;
  padding: 16px 28px;
  border-radius: 32px;
  margin: 30px 0px;
  transition: all 0.2s ease-in-out;
}
.customizeButton:hover{
  background-color: #0b4c7c;
  color: #fff;
}
.dashCard{
  position: relative;
}
.dashCard1::after{
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 50px;
  width: 5px;
  background-color: #0b4c7c;
  border-radius: 5px 0px 0px 5px;
}
.dashCard2::after{
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 50px;
  width: 5px;
  background-color: #02bb4c;
  border-radius: 5px 0px 0px 5px;
}
.dashCard3::after{
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 50px;
  width: 5px;
  background-color: #0093ff;
  border-radius: 5px 0px 0px 5px;
}
.dashCard4::after{
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 50px;
  width: 5px;
  background-color: #ec00ff;
  border-radius: 5px 0px 0px 5px;
}
.otpInput{
  width: 50px !important;
  height: 50px;
  font-size: 20px;
  text-align: center;
  margin-right: 10px;
  margin-left: 10px;
}